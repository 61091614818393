$(document).on('turbolinks:load', function() {
  // initProductCart();
  $('#delivery_type').change();
})

window.changePromoFields = changePromoFields;

// function initProductCart() {
//   $(document).on("click", ".product-option-item", function(){
//     let $this = $(this);
//     let id = $this.data('id');
//     let product_id = $('#product-show-wrapper').data('product_id');
//     let count = $('#product_count').val();
//     console.log('id, product_id', [id, count]);
//     $this.parents('.product-cart-option_type-wrapper').find('.product-option-item').removeClass('active');

//     if($this.hasClass( "form-inline-select-item" )){
//       $this.addClass('active');
//     }

//     let TypeValue = [];

//     $(".product-cart-option_type-wrapper").each(function(i, el) {
//       let option_type_id = $(el).data('option_type_id');

//       let active_id = null;
//       if ($(el).find('.product-option-item.active').length) {
//         active_id = $(el).find('.product-option-item.active').data('id');
//       } else {
//         active_id = $(el).find('.product-select-option-item').val();
//       }

//       let pair = [option_type_id, active_id];
//       // console.log('el', [$(el).data('option_type_id'), $(el).data('option_type_id')]);
//       TypeValue.push(pair);
//     });

//     $.ajax({
//       url: '/products/'+ product_id +'/set_product_options.js',
//       type: "GET",
//       data: { option_id: id, count: count, selected_options: TypeValue },
//       success: function(data, status) {

//       }
//     });

//   });
// }

$(document).on("change", ".product-select-option-item", function(){
  console.log('SELECT');
  update_product_option_block(this);
});

$(document).on("click", ".product-option-item", function(){
  console.log('ITEM');
  update_product_option_block(this);
});

function update_product_option_block(el){
 
  let $this = $(el);
  let id = $this.data('id');
  let product_id = $('#product-show-wrapper').data('product_id');
  let count = $('#product_count').val();
  console.log('id, product_id', [id, count]);
  $this.parents('.product-cart-option_type-wrapper').find('.product-option-item').removeClass('active');

  if($this.hasClass( "form-inline-select-item" )){
    $this.addClass('active');
  }

  let TypeValue = [];

  $(".product-cart-option_type-wrapper").each(function(i, el) {
    let option_type_id = $(el).data('option_type_id');

    let active_id = null;
    if ($(el).find('.product-option-item.active').length) {
      active_id = $(el).find('.product-option-item.active').data('id');
    } else {
      active_id = $(el).find('.product-select-option-item').val();
    }

    let pair = [option_type_id, active_id];
    // console.log('el', [$(el).data('option_type_id'), $(el).data('option_type_id')]);
    TypeValue.push(pair);
  });

  $.ajax({
    url: '/products/'+ product_id +'/set_product_options.js',
    type: "GET",
    data: { option_id: id, count: count, selected_options: TypeValue },
    success: function(data, status) {

    }
  });
}
  

$(document).on('click', '.cart-add_button', function() {
   let product_variant_id = $('#cart_product_variant_id').val();
   let count = $('#product_count').val();

   if (product_variant_id.length) {
      $.ajax({
        url: '/products/'+ product_variant_id +'/add_to_cart?format=js',
        method: 'get',
        data: { count: count },
        success:function (data, status) {
        }
      })
   }
});

$(document).on("change", ".cart-product-count-select", function(){
  let count = $(this).val();
  let id = $(this).data('id');

  $.ajax({
    url: '/products/'+ id +'/change_product_count.js',
    method: 'GET',
    data: { count: count }
  });
})


// $(document).on("click", ".product-img-bg__thumb", function(){
//   let $this = $(this);
//   let img_src = $this.data('img_url');

//   console.log('img_src', img_src);
//   var tempImage = new Image();
//   tempImage.src = img_src;
//   tempImage.width = '100%';

//   console.log('tempImage', tempImage);

//   if (tempImage.complete) {
//     handleImageLoad(tempImage);
//   } else {
//     // Wait for the image to load
//     $(tempImage).on('load', function() {
//       handleImageLoad(tempImage);
//     });
//   }

// })



// function handleImageLoad(image) {
//   var newImageHeight = $(image).height();
//   console.log('newImageHeight', newImageHeight);
//   $('#product-show-main_img-wrapper').animate({ height: newImageHeight }, 500, function() {
//     $('#product-show-main_img').attr('src', image.src);
//   });
// }


$(document).on("click", ".product-img-bg__thumb", function() {
  let $this = $(this);
  let img_src = $this.data('img_url');

  console.log('img_src', img_src);

  // Create a hidden temporary image element to load the new image
  var tempImage = new Image();
  tempImage.style.visibility = 'hidden';
  tempImage.style.position = 'absolute';
  tempImage.onload = function() {
    // Calculate the new image's height while maintaining its aspect ratio
    var containerWidth = $('#product-show-main_img-wrapper').width();
    var aspectRatio = tempImage.width / tempImage.height;
    var newImageHeight = containerWidth / aspectRatio;
    console.log('newImageHeight', newImageHeight);

    // Animate the container height first
    $('#product-show-main_img-wrapper').animate({ height: newImageHeight }, 500, function() {
      // Once the animation is complete, set the new image source and fade it in
      $('#product-show-main_img').attr('src', img_src).fadeIn(300);
    });
  };

  tempImage.src = img_src;
});


$(document).on("change", "#delivery_type", function(){
  let id = $(this).val();

  if (id.length) {
    $.ajax({
      url: '/orders/delivery_addresses.js',
      action: 'delivery_addresses',
      method: 'get',
      data: { id: id },
      success: function(data, status) {
        // console.log('type', data['type']);
        // console.log('addresses', data['addresses']);
        // console.log('status', status);

        // if (data['type'] == 'address_classifier') {
        //   $('.manual_address_field').hide();
        //   $('.digital_product_field').hide();
        //   $('.address_classifier_field').show();

        //   let address_classifier = $('#delivery_address_classifier_id'); // Get the select element using its id
        //   address_classifier.empty();
        //   address_classifier.append('<option></option>');

        //   data['addresses'].forEach(function(address) {
        //     console.log('address', address[1]);
        //     address_classifier.append('<option value="' + address[1] + '">' + address[0] + '</option>');
        //   });

        // }  else if (data['type'] == 'digital_email') {
        //   $('.manual_address_field').hide();
        //   $('.address_classifier_field').hide();
        //   $('.digital_product_field').show();

        // } else {
        //   $('.manual_address_field').show();
        //   $('.address_classifier_field').hide();
        //   $('.digital_product_field').hide();
        // }


      }
    })
  } else {
    $('.manual_address_field').hide();
    $('.address_classifier_field').hide();
    $('.digital_product_field').hide();
  }
})


//======================= orders

$(document).on("change paste keyup", "#order_promo_code_text", function(){
  var $input = $(this);
  var code = $input.val();
  var total_amount = $('#order-total-amount').attr('data-total-amount');

  if (code.length > 0) {
    $.ajax({
      method: 'GET',
      data: { code: code, order_sum: total_amount },
      url: '/check_promo_code.js',
      success: function (data) {
        console.log('data', data);
        // if ($.isNumeric(data['promo'])){
        //   changePromoFields($input, true, data);
        //   $('#order_promo_code_id').val(data['promo']);
        // } else {
        //   changePromoFields($input, false, 0);
        //   $input.addClass('input-not-valid');
        //   $('#order_promo_code_id').val("");
        // }
      }
    });
  } else {
    $('#order_promo_code_id').val("");
    changePromoFields($input, false, 0);
    $input.removeClass('input-not-valid');
  }
})


function changePromoFields($input, validity, data){
  let newDiscountValue = 0, newDiscountText, newTotalValue;
  let $sumSpan = $('#order-total-amount');
  let startingAmount = $sumSpan.attr('data-total-amount');
  console.log('Yoo', [newDiscountValue, $sumSpan, startingAmount])

  $input.toggleClass('promo-valid', validity);
  $input.toggleClass('input-not-valid', !validity);

  if (data.type == 'percentage') {
    newDiscountValue = data.percentage;
    newDiscountText = newDiscountValue + '%';
    if (validity){
      newTotalValue = newSumWithDiscountPercentage(startingAmount, newDiscountValue);
    }
  } else if (data.type == 'discount_sum'){
    newDiscountValue = data.discount_sum;
    newDiscountText = newDiscountValue + '€';
    if (validity){
      newTotalValue = newSumWithDiscountSum(startingAmount, newDiscountValue);
      console.log('newTotalValue', newTotalValue)
    }
  }

  if (!validity) {
    newTotalValue = startingAmount;
  }

  let showPromoFields = validity && (newDiscountValue > 0);

  $('#order-total-amount').text(newTotalValue);
  $('#promo-discount .value').text(newDiscountText);

  // Discount %/€ field under promo code input
  $('#promo-discount').toggleClass('active', validity && newDiscountValue > 0);

  $sumSpan.toggleClass('old-value', showPromoFields);
  $('#order-with-discount').toggleClass('active', showPromoFields);
}

// discount = { type: '%/€', value: '100' }
function newSumWithDiscountPercentage(startingAmount, percent){
  let newValue = +startingAmount * (1 - +percent / 100);
  let deliverySum = discountSum();
  newValue += deliverySum;
  return newValue.toFixed(2);
}

function newSumWithDiscountSum(startingAmount, sum){
  console.log('startingAmount', startingAmount);
  console.log('sum', sum)
  let newValue = +startingAmount - +sum
  console.log('newValue', newValue);
  return newValue.toFixed(2);
}

function discountSum(){
  let selectedOption = $('#delivery_type').find(':selected');
  return parseFloat(+orderItemDeliveryPrice(selectedOption));
}

function orderItemDeliveryPrice(selector) {
    return selector.text().split('(').pop().split(')')[0].split(' ')[0];
}
